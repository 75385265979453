<template>
  <div class="app">
    <Header />
    <router-view />
    <Footer />
  </div>
  <div class="tel">
    <ul>
      <li>
        <img src="./assets/icon-WX.png" alt="" />
        <div class="call-details animated" style="display: none">
          <span class="arrows"></span>
          <img src="./assets/weixin.jpg" alt="wechat" />
          <div>联系电话：16605224073</div>
          <h5>微信扫一扫</h5>
        </div>
      </li>
    </ul>

    <!-- <div>
      <img src="./assets/homeimg/up.png" alt="">
    </div> -->
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: { Header, Footer },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: PingFang SC;
}
.tel {
  position: fixed;
  top: 40%;
  right: 30px;
  z-index: 999;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
     border-radius: 10px;

  ul {
    list-style: none;
    li {
     
      img {
        display: block;
        cursor: pointer;
        z-index: 100;
      }
      .call-details {
        position: absolute;
        top: 0px;
        left: -155px;
        background-color: #fff;
        border: 1px solid #e1e6ec;
        padding: 15px 10px;
        img {
          width: 120px;
          display: block;
          margin: 0 auto;
        }
        h5 {
          color: #fff;
          font-size: 14px;
          text-align: center;
          margin-top: 15px;
          background: #2f66ad;
          padding: 10px 0;
          border-radius: 4px;
          font-weight: 400;
        }
        .arrows {
          position: absolute;
          top: 12px;
          right: -11px;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-left: 11px solid #e1e6ec;
          z-index: 99;
        }
        // .arrows:before {
        //   content: " ";
        //   // width: 80px;
        //   // height: 45px;
        //   top: -20px;
        //   right: -52px;
        //   position: absolute;
        //   background: transparent;
        // }
        .arrows:after {
          content: " ";
          top: -6px;
          right: 1px;
          position: absolute;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          z-index: 1000;
          border-left: 10px solid #f6f8fb;
        }
      }
      .animated {
        animation-duration: 1s;
        animation-fill-mode: both;
      }
      &:hover{
        .call-details{
          display: block !important;

        }
      }
    }
  }
}
</style>
