<template>
  <div class="hello">
    <nav
      style="background-color: transparent"
      :class="[show ? 'out1' : 'out', wzsnav, animated]"
    >
      <div class="nav-content clearFix">
        <div class="nav-box clearFix">
          <div class="logo">
            <a href="#" class="router-link-active">
              <img src="../assets/homeimg/logo-text.png" alt="" v-if="!show" />
              <img src="../assets/homeimg/logo-text2.png" alt="" v-if="show" />
            </a>
          </div>
          <ul class="nav-list clearFix" style="margin-left:130px">
            <li class="nav-item" v-for="(item, index) in list" :key="index">
              <a
                :href="item.link"
                id="nav-item01"
                :class="[item.on ? 'on' : '']"
                @click="click(item, index)"
                >{{ item.title }}</a
              >
            </li>
            <!-- <li class="nav-item">
              <a href="#/attract" id="nav-item01">招商系统</a>
            </li>
            <li class="nav-item">
              <a href="#/investment" id="nav-item01">招商插件</a>
            </li>
            <li class="nav-item">
              <a href="#/help" id="nav-item01">帮助中心</a>
            </li> -->
          </ul>
        </div>
        <div class="login">
          <!-- <div class="tips"> -->
            <!-- <img src="../assets/homeimg/horn.png" alt="" /> -->
            <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.241"
              height="15.021"
              viewBox="0 0 18.241 15.021"
            >
              <path
                id="id1"
                data-name="id1"
                d="M382.726,48.647h-3.519a.731.731,0,0,0-.724.735v.229a.729.729,0,0,0,.724.735h3.517a.729.729,0,0,0,.724-.735v-.229A.727.727,0,0,0,382.726,48.647Zm-8.163-6.312c-1.094.882-3.385,2.119-5.032,3.309h0c-.019.012-.038.078-.057.09-.2.111-.283.417-1.341.417h-2.065a.752.752,0,0,0-.86.82v5.044a.76.76,0,0,0,.86.849h2.072c1.054,0,1.138.306,1.332.417.019.009.038.078.057.087l0,0c1.6,1.188,3.9,2.522,5.032,3.371.339.255,1.473.7,1.473-.874V43.138c0-1.577-1.141-1.072-1.471-.8Zm3.248,3.477a.528.528,0,0,0,.827.189l2.892-2.475a.85.85,0,0,0,.151-1.023l-.1-.189a.528.528,0,0,0-.827-.189L377.857,44.6a.853.853,0,0,0-.151,1.025l.106.189Zm3.719,9.673-2.892-2.473a.528.528,0,0,0-.827.186l-.106.189a.85.85,0,0,0,.151,1.025l2.892,2.473a.527.527,0,0,0,.827-.186l.106-.189a.856.856,0,0,0-.151-1.025Z"
                transform="translate(-365.206 -41.994)"
                fill="#5fe3f3"
              />
            </svg>
            <span>[12-22]2022年1月结算日提前的通知</span>
          </div> -->
          <div class="dianji" @mouseenter="loginenter" @mouseleave="fn2" style="margin-left:500px">
            <button class="btn" id="dl">
              <a href="http://kqqx.wanzhuansheng.com/login" target="_blank" @click="login('a')">登录</a>
            </button>
            <div ref="loginout" class="icon"></div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
// import * as animationData from "@/assets/dh/login.json";
import lottie from "lottie-web";

export default defineComponent({
  components: {},
  setup() {
    const state = reactive({
      show: false,
      animated: "animated",
      wzsnav: "wzs-nav",
      lottie: {},
      on: true,
      list: [
        {
          title: "首页",
          link: "#/",
          on: true,
        },
        {
          title: "招商系统",
          link: "#/attract",
          on: false,
        },
        // {
        //   title: "招商插件",
        //   link: "#/investment",
        //   on: false,
        // },
        //         {
        //   title: "花惠联盟",
        //   link: "#/flower",
        //   on: false,
        // },
        {
          title: "帮助中心",
          link: "#/help",
          on: false,
        },
      ],
    });
    onMounted(() => {
      window.addEventListener("scroll", () => {
        if (document.documentElement.scrollTop > 100) {
          state.show = true;
          // document.getElementById("id1").style.fill = "#347dc0";
        } else {
          state.show = false;
          // document.getElementById("id1").style.fill = "#5fe3f3";
        }
      });
    });

    const loginout = ref();
    const fn = (animationData) => {
      lottie.destroy();
      state.lottie = lottie.loadAnimation({
        container: loginout.value,
        renderer: "svg",
        loop: false,
        animationData: animationData,
      });
    };
    const loginenter = () => {
      fn(require("@/assets/dh/login.json"));
    };
    const fn2 = () => {
      fn(require("@/assets/dh/loginout.json"));
      document.getElementById("dl").style.backgroundColor = "";
    };
    const login = (link) => {
      lottie.destroy();
      console.log("修改背景色", link);
      document.getElementById("dl").style.backgroundColor = "#3FC1E2";
    };
    const click = (item, index) => {
      console.log(item);
      for (let i = 0; i < state.list.length; i++) {
        if (i == index) {
          state.list[i].on = true;
        } else {
          state.list[i].on = false;
        }
      }
    };
    const fn3 = () => {
      console.log(window.location.hash);
      for (let i = 0; i < state.list.length; i++) {
        if (window.location.hash == state.list[i].link) {
          state.list[i].on = true;
        } else {
          state.list[i].on = false;
        }
      }
    };
    fn3();

    return {
      ...toRefs(state),
      loginout,
      fn2,
      loginenter,
      login,
      click,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hello {
  .wzs-nav {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 70px;
    .nav-content {
      padding: 15px 10px;
      max-width: 1300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .nav-box {
        display: flex;
        align-items: center;
        .nav-list {
          margin-left: 20px;
          height: 36px;
          display: flex;
          align-items: center;
          .nav-item {
            padding: 0 40px;
            font-size: 14px;
            line-height: 36px;
            font-weight: 600;
            .on {
              color: #2ddbff;
            }
          }
        }
      }
      .login {
        display: flex;
        align-items: center;
        .tips {
          display: flex;
          align-items: center;
          span {
            color: #5fe3f3;
            display: inline-block;
            line-height: 24px;
            margin-left: 11px;
          }
        }
        .dianji {
          position: relative;
          width: 125px;
          height: 49px;
          margin-left: 88px;
          color: #ffffff;
          // background-color: #000b3d;
          font-size: 15px;
          .btn {
            width: 125px;
            height: 49px;
            border: 1px solid #ffffff;
            color: #ffffff;
            background-color: rgba($color: #000000, $alpha: 0);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            cursor: pointer;
            a {
              width: 123px;
              height: 47px;
              display: inline-block;
              line-height: 47px;
            }
          }
          .icon {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
        }
      }
    }
    .clearFix::after {
      display: block;
      content: "";
      clear: both;
      visibility: hidden;
      height: 0;
    }
  }
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  .out {
    transition: background 0.2s ease-in 0.1s;
  }
  .out1 {
    background-color: hsla(0, 0%, 100%, 0.9) !important;
    color: #333;
    a {
      color: #5d5d5d;
    }
    .login {
      .btn {
        color: #5d5d5d !important;
        background-color: rgba($color: #ffffff, $alpha: 0) !important;
        border: 1px solid #000b3d !important;
      }
      .tips {
        span {
          color: #347dc0 !important;
        }
      }
    }
  }
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
  text-decoration: none;
}
</style>
