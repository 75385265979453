<template>
  <div class="home" ref="bigbox">
    <div class="wzs-banner">
      <!-- <img src="../assets/homeimg/banner.png" alt="" /> -->
    </div>
    <div class="info">
      <div class="info-box">
        <div><h5>对接平台</h5></div>
        <div class="img">
          <img src="../assets/homeimg/all6.png" alt=""/>
        </div>
        <div>
          <p>更多平台敬请期待</p>
        </div>
      </div>
    </div>
  </div>
  <div class="forte-one">
    <div class="home-title">
      <h1>4大独特优势</h1>
    </div>
    <ul class="forte-one-list">
      <li
          class="forte-one-item"
          @mouseenter="mouseenter(require('@/assets/dh/13.json'), 'box4')"
          id="box4-item1"
          @mouseleave="mouseleave('box4')"
      >
        <img src="../assets/homeimg/icon13.png" alt="" v-if="box4"/>
        <div id="box4" style="width: 130px; margin: 0 auto"></div>
        <h5 id="box4-h5">特色功能</h5>
        <p>
          全网独有的Rid跑单功能，全自动对接渠道的api接口，结算方式多样化，可支持订单量，GMV以及服务费等方式来进行结算，节省人工成本以及渠道开支</p>
      </li>
      <li
          class="forte-one-item"
          @mouseenter="mouseenter(require('@/assets/dh/01.json'), 'box')"
          id="box-item1"
          @mouseleave="mouseleave('box')"
          style="margin: 0 0%"
      >
        <img src="../assets/homeimg/icon03.png" alt="" v-if="box"/>
        <div id="box" style="width: 130px; margin: 0 auto"></div>
        <h5 id="box-h5">强大的技术支持</h5>
        <p>
          无需开发，无需懂技术，数十名三年以上工具开发工程师为您提供强大的开发能力支持，保障系统安全稳定运行
        </p>
      </li>
      <li
          class="forte-one-item"
          @mouseenter="mouseenter(require('@/assets/dh/02.json'), 'box2')"
          id="box2-item1"
          @mouseleave="mouseleave('box2')"
        style="margin: 0 0%"
      >
        <img src="../assets/homeimg/icon01.png" alt="" v-if="box2"/>
        <div id="box2" style="width: 130px; margin: 0 auto"></div>
        <h5 id="box2-h5">专业的产品迭代能力</h5>
        <p>
          多名资深产品经理，不断根据招商团长反馈及业界动态，深挖用户痛点，持续迭代升级，满足您的使用需求
        </p>
      </li>
      <li
          class="forte-one-item"
          @mouseenter="mouseenter(require('@/assets/dh/03.json'), 'box3')"
          id="box3-item1"
          @mouseleave="mouseleave('box3')"
      >
        <img src="../assets/homeimg/icon02.png" alt="" v-if="box3"/>
        <div id="box3" style="width: 130px; margin: 0 auto"></div>
        <h5 id="box3-h5">贴心的用户服务体系</h5>
        <p>
          我们始终秉承“客户第一、服务至上”的服务宗旨，专属客服为您提供更贴心、更高效、更懂您的1V1服务
        </p>
      </li>

    </ul>
  </div>
  <div class="forte-two">
    <div class="home-title">
      <h1>14系统优势</h1>
    </div>
    <ul class="forte-two-list">
      <li
          class="forte-two-item"
          @mouseenter="mouseenter2(item)"
          @mouseleave="mouseleave2(item)"
          v-for="(item, index) in list"
          :key="index"
      >
        <img :src="item.src" alt="" v-if="item.show"/>
        <div :id="item.id" style="width: 100px; margin: 0 auto"></div>
        <h5 :id="item.id + '-h5'">{{ item.title }}</h5>
      </li>
    </ul>
    <div class="home-title">
      <h1>独家特色功能</h1>
      <span style="color: white;opacity: 0.95;font-size: 16pt;width: 800px;line-height: normal;display: inline-block;overflow: auto;white-space: normal;text-overflow: clip">全网独有的Rid跑单功能，全自动对接渠道的api接口，结算方式多样化 <br> 可支持订单量，GMV以及服务费等方式来进行结算，节省人工成本以及渠道开支</span>
    </div>
  </div>
  <div class="partner">
    <div class="home-title" id="par">
      <h1>合作伙伴</h1>
    </div>
    <ul class="partner-list">
      <li style="list-style-type: none">
        <div><img src="../assets/homeimg/par/_0000_图层-2.png"><span>高佣联盟</span></div>
        <div><img src="../assets/homeimg/par/_0001_图层-3.png"> <span>好省</span></div>
        <div><img src="../assets/homeimg/par/_0002_图层-4.png"> <span>好券</span></div>
        <div><img src="../assets/homeimg/par/_0003_图层-5.png"> <span>什么值得买</span></div>
        <div><img src="../assets/homeimg/par/_0004_图层-6.png"> <span>粉象生活</span></div>
        <div><img src="../assets/homeimg/par/_0005_图层-7.png"> <span>省钱快报</span></div>
        <div><img src="../assets/homeimg/par/_0006_图层-8.png"> <span>淘粉吧</span></div>
        <div><img src="../assets/homeimg/par/_0007_图层-9.png"> <span>花惠联盟</span></div>
        <div><img src="../assets/homeimg/par/_0008_图层-10.png"> <span>慢慢买</span></div>
        <div><img src="../assets/homeimg/par/_0009_图层-11.png"> <span>福袋生活</span></div>
        <!--        <img src="../assets/homeimg/par/_0010_图层-12.png">-->
        <div><img src="../assets/homeimg/par/13.png"> <span>新势联盟</span></div>
        <div><img src="../assets/homeimg/par/14.png"> <span>猴赛雷</span></div>
        <div><img src="../assets/homeimg/par/15.png"> <span>乐购联盟</span></div>
        <div><img src="../assets/homeimg/par/16.png"> <span>百惠生活</span></div>
        <div><img src="../assets/homeimg/par/17.png"> <span>霆全科技</span></div>
        <div><img src="../assets/homeimg/par/18.png"> <span>京惠达</span></div>
        <div><img src="../assets/homeimg/par/19.jpg"> <span>买手妈妈</span></div>
        <div><img src="../assets/homeimg/par/20.jpg"> <span>好单库</span></div>
        <div><img src="../assets/homeimg/par/21.jpg"> <span>盖得排行</span></div>
        <div><img src="../assets/homeimg/par/22.png"> <span>全球骑士购</span></div>

      </li>
    </ul>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs, ref} from "vue";
import lottie from "lottie-web";
// import * as animationData from "@/assets/dh/01.json";
import CanvasNest from "canvas-nest.js";

export default defineComponent({
  name: "Lottie",
  setup() {
    const state = reactive({
      lottie: {},
      box: true,
      box2: true,
      box3: true,
      box4: true,
      list: [
        {
          id: "icon04",
          jsonlink: require("@/assets/dh/04.json"),
          show: true,
          src: require("../assets/homeimg/icon04.png"),
          title: "全网独有的Rid跑单功能，全自动对接渠道的api接口，结算方式多样化",
        },
        {
          id: "icon05",
          jsonlink: require("@/assets/dh/05.json"),
          show: true,
          src: require("../assets/homeimg/icon05.png"),
          title: "团队高校管理",
        },
        {
          id: "icon06",
          jsonlink: require("@/assets/dh/06.json"),
          show: true,
          src: require("../assets/homeimg/icon06.png"),
          title: "商家信息录入保存系统数据库安全不易丢失",
        },
        {
          id: "icon07",
          jsonlink: require("@/assets/dh/07.json"),
          show: true,
          src: require("../assets/homeimg/icon07.png"),
          title: "商品添加方便快捷",
        },
        {
          id: "icon08",
          jsonlink: require("@/assets/dh/08.json"),
          show: true,
          src: require("../assets/homeimg/icon08.png"),
          title: "佣金变化实时提醒避免挖单",
        },
        {
          id: "icon09",
          jsonlink: require("@/assets/dh/09.json"),
          show: true,
          src: require("../assets/homeimg/icon09.png"),
          title: "支持捆绑公众号，建立您的营销平台",
        },
        {
          id: "icon10",
          jsonlink: require("@/assets/dh/10.json"),
          show: true,
          src: require("../assets/homeimg/icon10.png"),
          title: "分公司轻松管理 无限复制",
        },
        {
          id: "icon11",
          jsonlink: require("@/assets/dh/11.json"),
          show: true,
          src: require("../assets/homeimg/icon11.png"),
          title: "增加财务管控避免各类小动作",
        },
        {
          id: "icon12",
          jsonlink: require("@/assets/dh/12.json"),
          show: true,
          src: require("../assets/homeimg/icon12.png"),
          title: "员工业绩实时统计无需繁琐统计计算",
        },

        // {
        //   id: "icon13",
        //   jsonlink: require("@/assets/dh/13.json"),
        //   show: true,
        //   src: require("../assets/homeimg/icon13.png"),
        //   title: "数据精准统计",
        // },
        {
          id: "icon14",
          jsonlink: require("@/assets/dh/14.json"),
          show: true,
          src: require("../assets/homeimg/icon14.png"),
          title: "多团长同时使用",
        },
        {
          id: "icon15",
          jsonlink: require("@/assets/dh/15.json"),
          show: true,
          src: require("../assets/homeimg/icon15.png"),
          title: "批量审核 拒绝产品",
        },
        {
          id: "icon16",
          jsonlink: require("@/assets/dh/16.json"),
          show: true,
          src: require("../assets/homeimg/icon16.png"),
          title: "脱离团长账号进行发布活动 审核产品",
        },
        {
          id: "icon17",
          jsonlink: require("@/assets/dh/17.json"),
          show: true,
          src: require("../assets/homeimg/icon17.png"),
          title: "成交订单实时获取",
        },
      ],
    });

    const fn = (animationData, a) => {
      lottie.destroy();
      state.lottie = {}
      state.lottie = lottie.loadAnimation({
        container: document.getElementById(a),
        renderer: "svg",
        loop: false,
        animationData: animationData,
      });
    };

    const bigbox = ref();

    onMounted(() => {
      const cn = new CanvasNest(bigbox.value);
      cn.destroy();
    });
    const mouseenter = (a, b) => {
      fn(a, b);
      document.getElementById(b + "-item1").style.boxShadow =
          "0 0 24px 0 rgb(47 102 173 / 18%)";
      document.getElementById(b + "-item1").style.transform =
          "translate3d(0,-10px,0)";
      document.getElementById(b + "-h5").style.color = "#347dc0";
      state[b] = false;
    };
    const mouseenter2 = (a) => {
      fn(a.jsonlink, a.id);
      document.getElementById(a.id + "-h5").style.opacity = "1";
      a.show = false;
    };
    const mouseleave = (b) => {
      lottie.destroy();
      state.lottie = {}
      state[b] = true;
      document.getElementById(b + "-item1").style.boxShadow = "";
      document.getElementById(b + "-item1").style.transform = "";
      document.getElementById(b + "-h5").style.color = "";
    };
    const mouseleave2 = (a) => {
      a.show = true;
      lottie.destroy();
      state.lottie = {}
      document.getElementById(a.id + "-h5").style.opacity = "";
    };

    return {
      ...toRefs(state),

      bigbox,
      mouseenter,
      mouseleave,
      mouseenter2,
      mouseleave2,
    };
  },
});
</script>
<style scoped lang="scss">
.home {
  position: relative;

  .wzs-banner {
    width: 100%;
    min-width: 1320px;
    height: 980px;
    background-image: url("../assets/homeimg/banner.png");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    img {
      width: 100%;
      display: block;
    }
  }

  .info {
    position: absolute;
    left: 0;
    bottom: -225px;
    z-index: 9;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;

    .info-box {
      width: 1500px;
      height: 400px;
      margin: 0 auto;
      background-color: #fff;
      box-shadow: 0 0 24px 0 rgb(47 102 173 / 18%);
      border-radius: 30px;
      padding: 40px 100px 0;
      box-sizing: border-box;
      text-align: center;

      h5 {
        font-size: 20px;
        text-align: left;
      }

      .img {
        //margin: 90px 0;
      }

      p {
        cursor: pointer;
      }
    }
  }
}

.forte-one {
  text-align: center;
  width: 1300px;
  padding: 300px 10px 10px;
  box-sizing: border-box;
  margin: 0 auto;

  .home-title {
    h1 {
      color: #3e3e3e;
    }
  }

  .forte-one-list {
    padding-top: 30px;
    // height: 323px;
    list-style: none;
    box-sizing: border-box;
    display: flex;

    .forte-one-item {
      width: 30%;
      box-sizing: border-box;
      padding: 50px 20px 20px;
      background-color: #fff;
      border-radius: 16px;
      transition: all 0.3s;
    }

    .on {
      box-shadow: 0 0 24px 0 rgb(47 102 173 / 18%);
    }

    img {
      width: 130px;
    }

    h5 {
      font-size: 18px;
      font-weight: 600;
      padding-top: 30px;
      padding-bottom: 15px;
    }

    p {
      font-size: 14px;
      color: #484848;
      line-height: 20px;
      font-weight: 400;
    }
  }
}

.home-title {
  background-image: url("../assets/homeimg/logo.png");
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  height: 125px;
  background-size: auto 100%;
  line-height: 125px;
}

.forte-two {
  background-image: url("../assets/homeimg/banner02.png");
  width: 100%;
  min-width: 1320px;
  height: 1152px;
  padding-top: 100px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: auto 100%;
  text-align: center;

  .home-title {
    h1 {
      color: #5fe2f2;
    }
  }

  .forte-two-list {
    width: 1300px;
    margin: 0 auto;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    .forte-two-item {
      width: 10%;
      height: 85px;
      box-sizing: border-box;
      margin-bottom: 100px;
      margin-left: 8%;
      color: #ffffff;

      h5 {
        opacity: 0.57;
      }
    }

    .forte-two-item:nth-child(1) {
      margin-left: 0;
      height: 70px;
    }

    .forte-two-item:nth-child(7) {
      margin-left: 0;
    }

    .forte-two-item:nth-child(13) {
      margin-left: 0;
    }
  }


}

.partner {
  width: 1300px;
  height: 1300px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 50px;

  img {
    width: 150px;
    padding: 40px 40px;
  }

  div {
    width: 230px;
    //padding: 40px 0;
    margin: 15px;
    float: left;
  }

}

#par {
  float: none;
}
</style>
