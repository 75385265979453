import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/attract',
    name: 'attract',
    component: () => import('../views/attract.vue')
  },
  {
    path: '/investment',
    name: 'investment',
    component: () => import('../views/investment.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/help.vue')
  },
  {
    path: '/flower',
    name: 'flower',
    component: () => import('../views/flower.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order.vue')
  }, {
    path: '/ex',
    name: 'ex',
    component: () => import('../views/ex.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
