<template>
  <div class="wzs-foot">
    <!-- <div class="foot-nav">
      <a href="#/">首页</a>
      <span>|</span>
      <a href="#/attract">招商系统</a>
      <span>|</span>
      <a href="#/order">服务订购</a>
      <span>|</span>
      <a href="#/investment">招商插件</a>
      <span>|</span>
      <a href="#/help">帮助中心</a>
    </div> -->
    <a href="https://beian.miit.gov.cn/" target="_blank" class="filing">京ICP备2022034137号-3</a>
  </div>
</template>
<style  lang="scss" scoped>
.wzs-foot {
  width: 100%;
  min-width: 1320px;
  text-align: center;
  background-color: #2d2d2e;
  padding: 15px 0;
  a {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
  }
  .foot-nav {
    width: 100%;
    span {
      color: #fff;
      padding: 0 10px;
    }
  }
  .filing {
    display: block;
    // margin-top: 20px;
  }
}
</style>